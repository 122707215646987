import React from 'react';
import { Container } from 'react-bootstrap';

/**
 * Component for the 2024 NT "Why Vote Green" section.
 */
export const NT24WVG = () => (
  <Container>
    <div className="container pt-1">
      <p>The major parties have let us down. We can&apos;t keep voting for the same two parties &amp; expecting different results.</p>
      <p>Change might not happen overnight, but it begins with voting for someone who will fight for you.</p>
      <p>The Greens will fight for you to:</p>
      <ul>
        <li>Strengthen renters&apos; rights and implement a 2 year rent freeze</li>
        <li>Restore integrity in our political institutions</li>
        <li>Protect our unique environment by introducing biodiversity laws and fixing our broken water laws</li>
      </ul>
    </div>
  </Container>
);

export default NT24WVG;
