import React, { Component, Fragment, ReactNode } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import Ballot from '~/components/sections/HowToVote/Ballot/Ballot';
import BallotComingSoon from '~/components/sections/HowToVote/BallotComingSoon/BallotComingSoon';
import HowToVoteInstructions from '~/components/sections/HowToVote/HowToVoteInstructions/HowToVoteInstructions';
import ErrorBoundary from '~/components/wrappers/ErrorBoundary/ErrorBoundary';
import { REGION } from '~/helpers/constants/ElectorateTypes';
import withConfig from '~/helpers/providers/withConfig';
import withElection from '~/helpers/providers/withElection';
import withElectorates from '~/helpers/providers/withElectorates';
import withPreferences from '~/helpers/providers/withPreferences';
import { Config } from '~/helpers/types/Config';
import { Election } from '~/helpers/types/Election';
import { SelectedElectorates } from '~/helpers/types/Electorate';
import { Preference } from '~/helpers/types/Preference';
import { actions, connect } from '~/store';

import { LoadingIcon } from '@australiangreens/components';
import Hidden from '@material-ui/core/Hidden';

import { BallotType } from '../../../helpers/types/BallotType';
import HowToVoteTabs from './HowToVoteTabs/HowToVoteTabs';

/** Fancy loading icon that imitates a how to vote card. */
const HowToVoteLoader = () => (
  <ContentLoader height={470} width={300} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
    <rect x="149" y="61" rx="0" ry="0" width="0" height="0" />
    <rect x="128" y="63" rx="0" ry="0" width="0" height="0" />
    <rect x="16" y="22" rx="0" ry="0" width="273" height="100" />
    <rect x="35" y="36" rx="0" ry="0" width="235" height="33" />
    <rect x="18" y="151" rx="0" ry="0" width="45" height="45" />
    <rect x="75" y="151" rx="0" ry="0" width="95" height="23" />
    <rect x="75" y="178" rx="0" ry="0" width="130" height="18" />
    <rect x="18" y="220" rx="0" ry="0" width="45" height="45" />
    <rect x="75" y="220" rx="0" ry="0" width="95" height="24" />
    <rect x="75" y="247" rx="0" ry="0" width="130" height="18" />
    <rect x="18" y="289" rx="0" ry="0" width="45" height="45" />
    <rect x="75" y="289" rx="0" ry="0" width="95" height="24" />
    <rect x="75" y="316" rx="0" ry="0" width="130" height="18" />
    <rect x="18" y="358" rx="0" ry="0" width="45" height="45" />
    <rect x="75" y="358" rx="0" ry="0" width="95" height="24" />
    <rect x="75" y="385" rx="0" ry="0" width="130" height="18" />
    <rect x="18" y="427" rx="0" ry="0" width="45" height="45" />
    <rect x="75" y="427" rx="0" ry="0" width="95" height="24" />
    <rect x="75" y="454" rx="0" ry="0" width="130" height="18" />
  </ContentLoader>
);

type HowToVoteProps = {
  /** The currently selected electorates. */
  electorates: SelectedElectorates;
  /** Preferences for the electorates, if loaded. */
  preferences?: {
    lower?: Preference[];
    upper?: Preference[];
  };
  /** Details of the election. */
  election: Election;
  /** HTV app config. */
  config: Config;
  candidateInfo: any;
};

type HowToVoteState = {
  /** The currently selected ballot.
   * Only relevant for mobile view.
   */
  currentBallot: BallotType;
  fetchedCandidates: string[];
};

/**
 * The 'How To Vote' section of the HTV app.
 * Shows the user one or more ballots, with instructions on how to vote.
 */
export class HowToVote extends Component<HowToVoteProps, HowToVoteState> {
  constructor(props: HowToVoteProps) {
    super(props);
    this.state = {
      currentBallot: props.config.showUpperFirst ? 'upper' : 'lower',
      fetchedCandidates: [],
    };
    this.changeBallot = this.changeBallot.bind(this);
  }

  componentDidMount() {
    if (this.props.config.getCandidateInfo && this.props.electorates?.lower && !this.state.fetchedCandidates.includes(this.props.electorates.lower.name)) {
      actions.getCandidateInfo();
      this.setState({ fetchedCandidates: [...this.state.fetchedCandidates, this.props.electorates.lower.name] });
    }
  }

  componentDidUpdate() {
    if (this.props.config.getCandidateInfo && this.props.electorates?.lower && !this.state.fetchedCandidates.includes(this.props.electorates.lower.name)) {
      actions.getCandidateInfo();
      this.setState({ fetchedCandidates: [...this.state.fetchedCandidates, this.props.electorates.lower.name] });
    }
  }

  changeBallot(_: any, ballot: BallotType): void {
    this.setState({ currentBallot: ballot });
  }

  renderCurrentBallot(): ReactNode {
    const { currentBallot } = this.state;
    if (currentBallot === 'lower') {
      return this.renderLowerBallot();
    }
    return this.renderUpperBallot();
  }

  renderLowerBallot(): ReactNode {
    const {
      preferences, electorates, election, config, candidateInfo,
    } = this.props;
    let heroImage;
    let profileImage;
    if (candidateInfo && candidateInfo[electorates?.lower?.name]) {
      heroImage = candidateInfo[electorates.lower.name].heroImage;
      profileImage = candidateInfo[electorates.lower.name].profileImage;
    }
    if (!preferences.lower) {
      return (
        <>
          <Hidden mdUp><img src={heroImage} alt="Hero" /></Hidden>
          <BallotComingSoon candidateInfo={candidateInfo[electorates.lower.name]} electorate={electorates.lower} />
        </>
      );
    }
    return (
      <>
        {!config.hideHowToVoteInstructions && (<HowToVoteInstructions electorate={electorates.lower} greensCandidate={preferences.lower.find((pref) => config.partyNameOnBallot.includes(pref.party))} config={config} />)}
        <div style={{ padding: '20px', backgroundColor: '#008c44' }}>
          <Ballot preferences={preferences.lower} electorate={electorates.lower} config={config} alignRight={config.ballot.showCandidateHero && !heroImage && profileImage} />
        </div>
      </>
    );
  }

  renderUpperBallot(): ReactNode {
    const {
      preferences, electorates, election, config, candidateInfo,
    } = this.props;
    if (!electorates.upper) {
      return null;
    }
    let heroImage;
    let profileImage;
    if (candidateInfo && candidateInfo[electorates?.upper?.name]) {
      heroImage = candidateInfo[electorates.upper.name].heroImage;
      profileImage = candidateInfo[electorates.upper.name].profileImage;
    }
    if (!preferences.upper) {
      return (
        <>
          <Hidden mdUp><img src={heroImage} alt="Hero" /></Hidden>
          <BallotComingSoon candidateInfo={candidateInfo[electorates.upper.name]} electorate={electorates.upper} />
        </>
      );
    }
    return (
      <>
        {!config.hideHowToVoteInstructions && (<HowToVoteInstructions electorate={electorates.upper} greensCandidate={preferences.upper.find((pref) => config.partyNameOnBallot.includes(pref.party))} config={config} />)}
        <div style={{ padding: '20px', backgroundColor: '#007236' }}>
          <Ballot preferences={preferences.upper} electorate={electorates.upper} config={config} />
        </div>
      </>
    );
  }

  renderSuperHero(): ReactNode {
    return (
      <Row style={{ paddingBottom: '15px', paddingTop: '30px' }}>
        <Col xs={{ span: 12 }} md={{ order: 1, span: 5 }} style={{ paddingBottom: '20px', height: '400px', paddingLeft: 0 }}>
          {this.renderSuperHeroHero('lower', 'right')}
        </Col>
        {/* eslint-disable-next-line no-nested-ternary */}
        <Col xs={{ span: 12 }} md={{ order: 1, span: 7 }} style={{ paddingBottom: '20px', height: '400px', paddingRight: 0 }}>
          {this.renderSuperHeroHero('upper')}
        </Col>
      </Row>
    );
  }

  renderSuperHeroHero(house = 'lower', fuzzDir = 'left'): ReactNode {
    const {
      candidateInfo, electorates,
    } = this.props;
    const { profileImage, centreOfFace } = candidateInfo[electorates[house].name];
    let { heroImage } = candidateInfo[electorates[house].name];
    let backgroundSize = 'cover';
    let backgroundPosition = 'right';
    let fuzz = '20';
    if (!heroImage) {
      heroImage = profileImage;
      backgroundSize = 'contain';
      backgroundPosition = 'left';
      fuzz = '10';
    }
    if (centreOfFace) {
      backgroundPosition = `${centreOfFace}%`;
    }
    return (
      <div style={{
        backgroundImage: `url(${heroImage})`,
        backgroundSize: `${backgroundSize}`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: 'center',
        backgroundPositionX: `${fuzzDir}`,
        width: fuzzDir === 'left' ? '100%' : '125%',
        clipPath: fuzzDir === 'right' ? undefined : 'polygon(13% 0, 100% 0%, 100% 100%, 0% 100%)',
        height: '100%',
      }}
      />
    );
  }

  renderHero(house = 'lower', fuzzDir = 'left'): ReactNode {
    const {
      candidateInfo, electorates,
    } = this.props;
    const { profileImage, centreOfFace, centreOfFaceY } = candidateInfo[electorates[house].name];
    let { heroImage } = candidateInfo[electorates[house].name];
    let backgroundSize = 'cover';
    let backgroundPositionX = 'right';
    let backgroundPositionY = 'center';
    let fuzz = '20';
    if (!heroImage) {
      heroImage = profileImage;
      backgroundSize = 'contain';
      backgroundPositionX = 'left';
      fuzz = '10';
    }
    if (centreOfFace) {
      backgroundPositionX = `${centreOfFace}%`;
      backgroundPositionY = `${centreOfFaceY}%`;
    }
    return (
      <div style={{
        backgroundImage: `linear-gradient( ${fuzzDir === 'left' ? '90deg' : '270deg'}, #ffffff 0%, rgba(255, 255, 255, 0) ${fuzz}% ), url(${heroImage})`,
        backgroundSize: `${backgroundSize}`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: `${backgroundPositionY}`,
        backgroundPositionX: `${backgroundPositionX}`,
        width: '100%',
        height: '100%',
      }}
      />
    );
  }

  render(): ReactNode {
    const {
      electorates, preferences, election, config, candidateInfo,
    } = this.props;
    const { currentBallot } = this.state;
    let heroImage = 'https://greens.org.au/sites/default/files/styles/hero/public/2024-04/2021_mosaic__v_april_2024_v2.jpg';
    let profileImage = 'https://greens.org.au/sites/default/files/styles/hero/public/2024-04/2021_mosaic__v_april_2024_v2.jpg';
    if (candidateInfo && candidateInfo[electorates?.lower?.name]) {
      heroImage = candidateInfo[electorates.lower.name].heroImage;
      profileImage = candidateInfo[electorates.lower.name].profileImage;
    }
    if (!config.ballot.labels) {
      config.ballot.labels = {
        lowerLabel: 'House of Reps',
        upperLabel: 'Senate',
      };
    }
    const { lowerLabel, upperLabel } = config.ballot.labels;
    if (!preferences) {
      return (
        <Container style={{ textAlign: 'center' }}>
          <LoadingIcon />
        </Container>
      );
    }
    return (
      <ErrorBoundary>
        <Hidden smDown>
          <Container style={{ paddingTop: config.election?.code === 'fed22' ? '20px' : 0, paddingLeft: config.ballot.showCandidateHero ? 0 : '15px', paddingRight: config.ballot.showCandidateHero && heroImage ? 0 : '15px' }}>
            { !config.hideUpper && ( // Show lower and upper ballots
              <Row style={{ paddingBottom: '15p' }}>
                {/* eslint-disable-next-line no-nested-ternary */}
                <Col xs={{ span: 12, order: config.showUpperFirst ? 2 : 1 }} md={{ order: 1, offset: (config.ballot.showCandidateHero && !heroImage && profileImage ? 2 : 0), span: config.electorates?.upperType === REGION ? 6 : (config.ballot.showCandidateHero ? (heroImage || profileImage ? 6 : 12) : 6) }} style={{ paddingBottom: config.ballot.showCandidateHero && !heroImage && profileImage ? "0" : "20px" }}>
                  {this.renderLowerBallot()}
                </Col>
                {/* eslint-disable-next-line no-nested-ternary */}
                <Col xs={{ span: 12, order: config.showUpperFirst ? 1 : 2 }} md={{ order: 2, span: config.electorates?.upperType === REGION ? 6 : (config.ballot.showCandidateHero && heroImage ? 6 : 6) }}>
                  {this.renderUpperBallot()}
                </Col>
              </Row>
            )}
            { config.hideUpper && ( // Show lower ballot only
              <Row style={{ paddingBottom: '15p' }}>
                {/* eslint-disable-next-line no-nested-ternary */}
                <Col xs={{ span: 12 }} md={{ span: 6, offset: 3 }} style={{ paddingBottom: config.ballot.showCandidateHero && !heroImage && profileImage ? '0' : '20px' }}>
                  {this.renderLowerBallot()}
                </Col>
              </Row>
            )}
          </Container>
        </Hidden>
        <Hidden mdUp>
          {upperLabel && !config.hideUpper && (<HowToVoteTabs config={config} currentBallot={currentBallot} changeBallot={this.changeBallot} />)}
          {(!upperLabel || config.hideUpper) && (<div style={{ height: '10px' }} />)}
          {this.renderCurrentBallot()}
        </Hidden>
      </ErrorBoundary>
    );
  }
}

export default connect(({ candidateInfo }) => ({ candidateInfo }))(withConfig(withElection(withPreferences(withElectorates(HowToVote)))));
